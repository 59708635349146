import React, { useState } from 'react';
import './login.css'; 
import logo from '../../assets/navbar/akira-ai.svg'; 
import loginImage from '../../assets/authentication-side-image.png';
import passwordHideIcon from '../../assets/password-hide-icon.svg';
import { useNavigate } from 'react-router-dom'; 

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate(); // Initialize navigate

    // Static login credentials
    const validCredentials = {
        username: 'demo@akira.ai',
        password: 'demo123'
    };

    const handleLogin = (event) => {
        event.preventDefault();

        if (!username || !password) {
            setLoginError('Please add valid credentials to proceed.');
            return;
        }

        setLoginError('');
        setErrorMessage('');

        // Check static credentials
        if (username === validCredentials.username && password === validCredentials.password) {
            navigate('/homepage'); 
        } else {
            setLoginError('Invalid username or password. Please try again.');
        }
    };

    const togglePasswordVisibility = () => {
        const passwordInput = document.getElementById('password');
        passwordInput.type = passwordInput.type === 'password' ? 'text' : 'password';
    };

    return (
        <div className="login-wrapper">
            <div className="login-left-container">
                <img src={loginImage} alt="Login illustration" />
            </div>
            <div className="login-right-container">
                <div className="agent-instruct-logo">
                    <img src={logo} alt="Logo" className="form-logo" />
                </div>
                <div className="login-form-wrapper">
                    <h2>Login</h2>
                    <div id="error-message" style={{ color: 'red' }}>{errorMessage}</div>
                    <form id="login-form" onSubmit={handleLogin} className="login-form">
                        <div className="username">
                            <input
                                type="text"
                                id="username"
                                placeholder="User Name"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                            />
                        </div>
                        <div className="password">
                            <input
                                type="password"
                                id="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            <div className="password-hide-icon" onClick={togglePasswordVisibility}>
                                <img src={passwordHideIcon} alt="Toggle password visibility" />
                            </div>
                        </div>
                        <div id="login-error" style={{ color: 'red', display: loginError ? 'block' : 'none' }}>
                            {loginError}
                        </div>
                        <div className="submit-btn">
                            <button type="submit" id="login-button" disabled={!username || !password}>Login</button>
                        </div>
                        <div className="login-msg">
                            <p>
                                Don't have an account? 
                                <a href="signup.html" style={{ textDecoration: 'none' }}>
                                    <span>Signup</span>
                                </a>
                            </p>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;
