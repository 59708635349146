import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Banner from './components/banner/banner';
import Navbar from './components/navbar/navbar';
import Login from './components/login/login';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/homepage" element={<Main />} />
      </Routes>
    </Router>
  );
}

const Main = () => {
  return (
    <>
      <Navbar />
      <Banner />
    </>
  );
};

export default App;
