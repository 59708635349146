import React from "react";
import "./banner.css";
import bannerIcon1 from "../../assets/BannerSection/banner-card-1.svg";
import bannerIcon2 from "../../assets/BannerSection/banner-card-2.svg";
import bannerIcon3 from "../../assets/BannerSection/banner-card-3.svg";
const Banner = () => {
  return (
    <div>
    <section className="banner-section">
      <div className="container">
        <div className="banner-content-wrapper">
          <h1>Transform Contract Management <br/> with Agentic AI</h1>
          <p>
          Seamlessly automate wage calculations, gate pass approvals, and ensure compliance with labor regulations, all within one platform powered by AI.
          </p>
          <div className="banner-button-wrapper">
            <a href="https://wages-akira-ai.lab.neuralcompany.team/">
              <button className="banner-button">
                <p>Wage Reconciliation</p>
              </button>
              </a>
              <a href="https://gatepass-akira-ai.lab.neuralcompany.team/">
              <button className="banner-button">
                <p>Gate Pass Authentication</p>
              </button>
              </a>
          </div>
        </div>
      </div>
     
    </section>
    <section className="cards-wrapper-section">
     <div className="banner-cards-wrapper">
     <div className="banner-card banner-first-card">
       <img src={bannerIcon1} alt="banner-card-icon" />
       <h2>Agentic Workflow </h2>
       <p>
       Automate contract tasks like wage calculations, gate passes, and compliance with intelligent workflows.
       </p>
     </div>
     <div className="banner-card banner-second-card">
       <img src={bannerIcon2} alt="banner-card-icon" />
       <h2>OCR Integration</h2>
       <p>Convert physical documents into digital data, enabling seamless processing and reducing manual entry errors.</p>
     </div>
     <div className="banner-card banner-third-card">
       <img src={bannerIcon3} alt="banner-card-icon" />
       <h2>Image Analysis</h2>
       <p>
       Use AI to analyze labor documents and verify identities, ensuring faster, secure contract management.
       </p>
     </div>
   </div>
   </section>
   </div>
  );
};

export default Banner;
